import axios from 'axios';

const instance =  axios.create({
	baseURL: "https://api.endochecker.kori.app/api/"
});


function updateToken(){
	const token = localStorage.getItem('@endochecker/token');
	if (token) {
		instance.defaults.headers.common['Authorization'] = 'Bearer ' + token;
	} else {
		instance.defaults.headers.common['Authorization'] = null;
	}
}

export async function saveRanking(userId: string, ranking: string, reason: string, monthYear: string) {
	try {

		let response = await instance.post('/saveUserRanking',
			{
				"ranking": ranking,
                "userId": userId,
                "reason": reason,
                "monthYear": monthYear
			})
		if (response.status === 201) {
			return true;
		}else{
			return false;
		}
	} catch (error) {
		console.log("Erro ao salvar ranking" + error);
		return false;
	}
}

export async function getRanking(monthYear: String) {
	try {

		let response = await instance.get(`/getRanking/${monthYear}`)
        
		if (response.status === 200) {
			return response.data;
		}else{
			return null;
		}
	} catch (error) {
		console.log("Erro ao atualizar ranking" + error);
		return null;
	}
}


updateToken();

interface AuthProvider {
	isAuthenticated: boolean;
	signin(username: string, password: string): Promise<void>;
	signout(): void;
	checkToken(): Promise<void>;
  }
  
  export const authProvider: AuthProvider = {
	isAuthenticated: false,

	async signin(username: string, password: string) {
		try {
			let response = await instance.post('/authenticate',
				{
					"username": username,
					"password": password
				})
			if (response.status === 200) {
				localStorage.setItem('@endochecker/token', response.data.jwt);
				updateToken();
				authProvider.isAuthenticated = true;
			}
		} catch (error) {
			console.log("Erro ao se autenticar" + error);
		}
	},

	signout() {
		localStorage.removeItem('@endochecker/token');
		updateToken();
	  	authProvider.isAuthenticated = false;
	},

	async checkToken() {
		updateToken();
		try {
			let response = await instance.get(`/checktoken`)
			
			if (response.status !== 200) {
				authProvider.isAuthenticated = false;
			}
			authProvider.isAuthenticated = true;
		} catch (error) {
			console.log("Erro ao checar token" + error);
			authProvider.isAuthenticated = false;
		}
	}
  };
  