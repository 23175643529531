import {useState, useEffect} from 'react';
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ptBR from 'date-fns/locale/pt-BR';

import type { LoaderFunctionArgs } from "react-router-dom";
import {
  Form,
  Link,
  Outlet,
  RouterProvider,
  createBrowserRouter,
  redirect,
  useActionData,
  useFetcher,
  useLocation,
  useNavigation,
} from "react-router-dom";

import logo from './images/icon.png'
import gordura from './images/perda_gordura.png'
import muscular from './images/ganho_muscular.png'
import ambos from './images/gordura_muscular.png'
import {saveRanking, getRanking, authProvider} from './api/server';
import './App.css';
import { add } from 'date-fns';

interface UserRanking {
	id: number
 	onthYear: string
	name: string
	ranking: number
	reason: string
	userId: string
  }

  const router = createBrowserRouter([
	{
	  id: "root",
	  path: "/",
	  loader() {
		return { isAuthenticated: authProvider.isAuthenticated };
	  },
	  Component: Layout,
	  children: [
		{
		  index: true,
		  loader: protectedLoader,
		  Component: RankingPage,
		},
		{
		  path: "login",
		  action: loginAction,
		  loader: loginLoader,
		  Component: LoginPage,
		},
		{
		  path: "ranking",
		  loader: protectedLoader,
		  Component: RankingPage,
		},
		{
			path: "adicionar",
			action: addAction,
			loader: protectedLoader,
			Component: AddPage,
		  },
		  {
			path: "adicionar-historico",
			action: addHistoricalAction,
			loader: protectedLoader,
			Component: AddHistoricalPage,
		  },
	  ],
	},
	{
	  path: "/logout",
		action() {
		authProvider.signout();
		return redirect("/");
	  },
	},
  ]);


  export default function App() {
	return (
	  <RouterProvider router={router} fallbackElement={<p>Initial Load...</p>} />
	);
  }


function Layout() {
	let fetcher = useFetcher();
	registerLocale('ptBR', ptBR);
	setDefaultLocale("ptBR");

	useEffect(() => {
		const check = async () => {
			await authProvider.checkToken();
		}
		check();
	}, []);

	return (
		<div className="grid grid-cols-4">
			<div className='col-start-2 col-span-2'>
				<div className='flex flex-row justify-center'>
					<img className='w-40' src={logo} />
					<h1 className='self-center text-2xl'>Endochecker</h1>
				</div>
				{authProvider.isAuthenticated && <>
					<Link to="/adicionar" className="bg-slate-50 pb-1 rounded border-e-2 ps-2 pe-2 pt-2">Novo</Link>
					<Link to="/adicionar-historico" className="bg-slate-50 pb-1 rounded border-e-2 ps-2 pe-2 pt-2">Adicionar Histórico</Link>
					<Link to="/ranking" className="bg-slate-50 pb-1 bg-slate-100 pb-2 rounded border-e-2 ps-2 pe-2 pt-2">Ranking</Link>
					<div className='inline-block'>
					<fetcher.Form method="post" action="/logout">
						<button className="bg-slate-50 pb-1 bg-slate-100 pb-2 rounded border-e-2 ps-2 pe-2 pt-2" type="submit">
							Sair
						</button>
					</fetcher.Form>
					</div>
					<hr className="h-0.5 border-t-0 bg-neutral-100 opacity-100 dark:opacity-50" />
					</>
				}
				<Outlet />
			</div>
		</div>
	);
}

function LoginPage() {
	let location = useLocation();
	let params = new URLSearchParams(location.search);
	let from = params.get("from") || "/";
  
	let navigation = useNavigation();
	let isLoggingIn = navigation.formData?.get("username") != null;
  
	let actionData = useActionData() as { error: string } | undefined;
  
	return (
	  <div>
		<Form method="post" replace>
		  <input type="hidden" name="redirectTo" value={from} />
		  <div className="grid grid-cols-5 m-5">
				<label className='col-start-2 col-span-2'>Usuário:</label>
				<input name="username" className='border border-slate-400'/>
			</div>
			<div className="grid grid-cols-5 m-5">
				<label className='col-start-2 col-span-2'>Senha:</label>
				<input name="password" className='border border-slate-400' type="password"/>
			</div>
			<div className='flex flex-row justify-center'>
		  <button className='bg-slate-300 rounded-lg p-1' type="submit" disabled={isLoggingIn}>
			{isLoggingIn ? "Entrando..." : "Enviar"}
		  </button>
		  </div>
		  {actionData && actionData.error ? (
			<p style={{ color: "red" }}>{actionData.error}</p>
		  ) : null}

		</Form>
	  </div>
	);
  }

  async function loginAction({ request }: LoaderFunctionArgs) {
	let formData = await request.formData();
	let username = formData.get("username") as string | null;
	let password = formData.get("password") as string | null;
  
	if (!username || !password) {
	  return {
		error: "You must provide a username to log in",
	  };
	}

	try {
	  await authProvider.signin(username, password);
	} catch (error) {
	  return {
		error: "Invalid login attempt",
	  };
	}
  
	let redirectTo = formData.get("redirectTo") as string | null;
	return redirect(redirectTo || "/");
  }

  async function loginLoader() {
	if (authProvider.isAuthenticated) {
	  return redirect("/");
	}
	return null;
  }

  function protectedLoader({ request }: LoaderFunctionArgs) {
	if (!authProvider.isAuthenticated) {
	  let params = new URLSearchParams();
	  params.set("from", new URL(request.url).pathname);
	  return redirect("/login?" + params.toString());
	}
	return null;
  }


  function RankingPage() {
	const [startDate, setStartDate] = useState(new Date());
	const [listUser, setListUser] = useState<[UserRanking]>();

	const initialFetchData = async (startDate: String) => {
		setListUser(await getRanking(startDate));
	}

	useEffect(() => {
		const year = startDate.getFullYear();
		const month = startDate.getMonth() + 1;
		let monthYear = `${month}${year}`;
		initialFetchData(monthYear);
	}, [startDate]);

	return (
		<>
		<div className="grid grid-cols-5 m-5">
			<div className='col-span-5 mb-5 flex gap-4 justify-center'>
				<p className='font-bold'>Ranking do mês de</p>
				<div>
					<DatePicker
						selected={startDate}
						onChange={(date: Date) => setStartDate(date)}
						dateFormat="MM/yyyy"
						showMonthYearPicker
						showFullMonthYearPicker
					/>
				</div>
			</div>
			<label className=''>Posição</label>
			<label className='col-span-2'>Nome</label>
			<label className=''>% Gordura</label>
			<label className=''>Motivo</label>
		</div>
		{listUser?.map((value, index) => {
			let imgSrc = null
			switch (value.reason) {
				case "gordura":
					imgSrc = gordura;
					break;
				case "muscular":
					imgSrc = muscular;
					break;
				case "ambos":
				default:
					imgSrc = ambos;
					break;
			}
			let percent = value.ranking > 0 ? `+${value.ranking}` : value.ranking;

			return (<div className="grid grid-cols-5 m-5 items-center">
				<label className=''>{index+1}</label>
				<label className='col-span-2'>{value.name}</label>
				<label className=''>{percent}%</label>
				{value.reason !== "neutro" && <img className='w-20' src={imgSrc} />}
			</div>)
		})}
	</>
	)
  }

  function AddPage() {
	return 	(
		<div>
		<Form method="post" replace>
			<div className="grid grid-cols-5 m-5">
				<label className='col-start-2 col-span-2'>ID do Paciente:</label>
				<input name='userId' className='border border-slate-400'/>
			</div>

			<div className="grid grid-cols-5 m-5">
				<label className='col-start-2 col-span-2'>% Perda de Gordura:</label>
				<input name='ranking' className='border border-slate-400' ></input>
			</div>

			<div className="grid grid-cols-5 m-5">
				<label className='col-start-2 col-span-2'>Motivo:</label>
				<select name="reason">
					<option value="gordura">Perda de Gordura</option>
					<option value="muscular">Ganho Muscular</option>
					<option value="ambos">Perda de Gordura + Ganho Muscular</option>
					<option value="neutro">Ganho de Gordura</option>
				</select>
			</div>
			<div className='flex flex-row justify-center'>
			<button className='bg-slate-300 rounded-lg p-1' type="submit">Enviar</button>
			</div>

		</Form>
		</div>);
  }


  async function addAction({ request }: LoaderFunctionArgs) {
	let formData = await request.formData();
	let userId = formData.get("userId") as string | null;
	let ranking = formData.get("ranking") as string | null;
	let reason = formData.get("reason") as string | null;

	const date = new Date();
	const year = date.getFullYear();
	const month = date.getMonth() + 1;
	let monthYear = `${month}${year}`;
  

	if(!userId || !ranking || !reason){
	  return {
		error: "You must provide a username to log in",
	  };
	}
  
	try {
	  await saveRanking(userId, ranking, reason, monthYear);
	} catch (error) {
	  return {
		error: "Invalid login attempt",
	  };
	}

	return redirect("/ranking");
  }


  function AddHistoricalPage() {
	return 	(
		<div>
		<Form method="post" replace>
			<div className="grid grid-cols-5 m-5">
				<label className='col-start-2 col-span-2'>ID do Paciente:</label>
				<input name='userId' className='border border-slate-400'/>
			</div>

			<div className="grid grid-cols-5 m-5">
				<label className='col-start-2 col-span-2'>% Perda de Gordura:</label>
				<input name='ranking' className='border border-slate-400' ></input>
			</div>

			<div className='flex flex-row justify-center'>
			<button className='bg-slate-300 rounded-lg p-1' type="submit">Enviar</button>
			</div>

		</Form>
		</div>);
  }


  async function addHistoricalAction({ request }: LoaderFunctionArgs) {
	let formData = await request.formData();
	let userId = formData.get("userId") as string | null;
	let ranking = formData.get("ranking") as string | null;
	let reason = "historical";

	let monthYear = "012000";
  

	if(!userId || !ranking || !reason){
	  return {
		error: "You must provide a username to log in",
	  };
	}
  
	try {
	  await saveRanking(userId, ranking, reason, monthYear);
	} catch (error) {
	  return {
		error: "Invalid login attempt",
	  };
	}

	return redirect("/ranking");
  }